export const ImgBar1 = '/imagens/post1/barbeiro.webp';
export const ImgBar2 = '/imagens/post1/barbeiro2.webp';
export const avatarPerfil1 = '/imagens/autor.webp';

//marmitas

export const ImgMar1 = '/imagens/post2/marmitas1.webp';
export const ImgMar2 = '/imagens/post2/marmitas2.webp';

//financas
export const ImgFin1 = '/imagens/post3/financas1.webp';
export const ImgFin2 = '/imagens/post3/financas2.webp';

//adestramento

export const ImgAdestramento = '/imagens/post3/adestramento.webp';

const generateSlug = (titulo) => {
    return titulo
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9 ]/g, '')
        .trim()
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
};

const postsData = [
    {   id: 1, titulo: "Guia Completo para se Tornar um Barbeiro Profissional",
        subtitulo: "Dicas e Técnicas Essenciais para Dominar a Arte do Corte",
        conteudo: `
            <p>Voc&ecirc; j&aacute; sonhou em se tornar um barbeiro profissional, criando cortes de cabelo que deixam seus clientes mais confiantes e estilosos? Se a resposta for sim, este guia &eacute; para voc&ecirc;! Vamos explorar as etapas essenciais para dominar a arte do corte e se transformar em um barbeiro de sucesso.</p>
<h3>1. <strong>Educa&ccedil;&atilde;o e Treinamento</strong></h3>
<p>A primeira etapa para se tornar um barbeiro profissional &eacute; obter a educa&ccedil;&atilde;o adequada. Procure escolas de barbearia ou cursos especializados que ofere&ccedil;am uma forma&ccedil;&atilde;o completa. Esses programas geralmente cobrem t&eacute;cnicas de corte, estilos de barba, higiene, e at&eacute; mesmo gest&atilde;o de neg&oacute;cios.</p>
<h3>2. <strong>Ferramentas Essenciais</strong></h3>
<p>Um barbeiro &eacute; t&atilde;o bom quanto suas ferramentas. Invista em equipamentos de alta qualidade, incluindo:</p>
<p><strong>Tesouras de corte</strong>: Tesouras afiadas e de boa qualidade s&atilde;o essenciais para cortes precisos.</p>
<p><strong>M&aacute;quinas de corte</strong>: Escolha m&aacute;quinas com diferentes l&acirc;minas para versatilidade.</p>
<p><strong>Pentes e escovas</strong>: Pentes de diferentes tamanhos ajudam a criar estilos variados.</p>
<p><strong>Navalhas</strong>: Essenciais para acabamentos perfeitos e linhas precisas.</p>
<h3>3. <strong>Pr&aacute;tica Constante</strong></h3>
<p>A pr&aacute;tica leva &agrave; perfei&ccedil;&atilde;o. Comece cortando o cabelo de amigos e familiares para ganhar confian&ccedil;a e aperfei&ccedil;oar suas habilidades. Observe v&iacute;deos tutoriais e participe de workshops para aprender novas t&eacute;cnicas e tend&ecirc;ncias.</p>
<h3>4. <strong>Conhecimento das Tend&ecirc;ncias</strong></h3>
<p>Mantenha-se atualizado com as &uacute;ltimas tend&ecirc;ncias de moda e estilos de cabelo. Siga influenciadores de barbearia nas redes sociais, leia revistas especializadas e participe de eventos do setor.</p>
        `,
        conteudo2: `
           <h3>5. <strong>Desenvolva seu Estilo</strong></h3>
<p>Cada barbeiro tem um estilo &uacute;nico. Experimente diferentes t&eacute;cnicas e desenvolva um estilo que seja sua assinatura. Isso ajudar&aacute; a atrair clientes que apreciam seu trabalho espec&iacute;fico.</p>
<h3>6. <strong>Habilidades de Atendimento ao Cliente</strong></h3>
<p>Ser um bom barbeiro vai al&eacute;m das habilidades t&eacute;cnicas. Desenvolva boas habilidades de comunica&ccedil;&atilde;o e atendimento ao cliente. Ou&ccedil;a atentamente os desejos dos seus clientes, ofere&ccedil;a conselhos honestos e crie um ambiente confort&aacute;vel e acolhedor na sua barbearia.</p>
<h3>7. <strong>Marketing e Presen&ccedil;a Online</strong></h3>
<p>Em um mundo digital, ter uma forte presen&ccedil;a online &eacute; crucial. Crie perfis nas redes sociais para mostrar seu trabalho e interagir com potenciais clientes. Considere fazer um site profissional e usar o marketing digital para atrair mais clientes.</p>
<h3>8. <strong>Legaliza&ccedil;&atilde;o e Licenciamento</strong></h3>
<p>Certifique-se de estar em conformidade com as regulamenta&ccedil;&otilde;es locais. Isso pode incluir obter uma licen&ccedil;a para operar como barbeiro e garantir que seu estabelecimento atenda aos padr&otilde;es de higiene e seguran&ccedil;a.</p>
<h3>9. <strong>Feedback e Melhoria Cont&iacute;nua</strong></h3>
<p>Pe&ccedil;a feedback aos seus clientes e use-o para melhorar continuamente seus servi&ccedil;os. Participar de cursos avan&ccedil;ados e aprender novas t&eacute;cnicas &eacute; fundamental para se manter competitivo no mercado.</p>
<h3>Conclus&atilde;o</h3>
<p>Tornar-se um barbeiro profissional requer dedica&ccedil;&atilde;o, pr&aacute;tica e uma paix&atilde;o genu&iacute;na pela arte do corte. Com o treinamento adequado, boas ferramentas, e um compromisso com a excel&ecirc;ncia, voc&ecirc; pode dominar essa profiss&atilde;o e construir uma carreira de sucesso. Lembre-se, cada corte &eacute; uma obra de arte e cada cliente, uma nova oportunidade de mostrar seu talento!</p>
<h1><strong>Temos o curso ideal para voc&ecirc;:</strong></h1>
<p>&nbsp;</p>
        `,
        tags: [2, 3, 9, 14], 
        imgConteudo1: ImgBar1, 
        imgConteudo2: ImgBar2, 
        imgCard: ImgBar1, 
        imgAlt1: 'imagem de um barbeiro', 
        imgAlt2: 'imagem de uma barbearia', 
        autor: 'Teles', 
        avatarAutor: avatarPerfil1, 
        dataPost: '27/07/2024', 
        views: 150,
        linkCurso: 'https://www.aiqueproduto.com.br/produto/curso-de-barbeiro-profissional-2024'
    },
    {   id: 2, titulo: "Dicas para Preparar Marmitas Fitness Saudáveis",
        subtitulo: "Transforme sua alimentação com planejamento e organização",
        conteudo: `
            <p>Preparar marmitas fitness saud&aacute;veis pode parecer um desafio, mas com um pouco de planejamento e as dicas certas, &eacute; poss&iacute;vel tornar essa tarefa pr&aacute;tica e at&eacute; prazerosa. Aqui est&atilde;o algumas dicas para ajudar voc&ecirc; a preparar suas marmitas de forma eficiente e nutritiva.</p>
            <h4>1. Planeje seu card&aacute;pio semanalmente</h4>
            <p>Tire um tempo no final de semana para planejar o que voc&ecirc; vai comer durante a semana. Escolha receitas variadas que incluam todos os grupos alimentares: prote&iacute;nas magras, carboidratos complexos e muitas verduras e legumes.</p>
            <h4>2. Fa&ccedil;a compras inteligentes</h4>
            <p>Com a lista de receitas em m&atilde;os, fa&ccedil;a uma lista de compras detalhada. Compre alimentos frescos e de qualidade. Evite alimentos processados e ricos em s&oacute;dio e a&ccedil;&uacute;cares.</p>
            <h4>3. Cozinhe em grandes quantidades</h4>
            <p>Uma boa dica &eacute; cozinhar em grandes quantidades. Por exemplo, prepare um grande lote de arroz integral, quinoa ou outro gr&atilde;o saud&aacute;vel, e diferentes tipos de legumes assados ou cozidos. Assim, voc&ecirc; pode montar as marmitas variando os acompanhamentos.</p>
            <h4>4. Use recipientes adequados</h4>
            <p>Invista em recipientes de vidro ou pl&aacute;stico BPA free, que s&atilde;o seguros para aquecer no micro-ondas. Opte por aqueles que t&ecirc;m divis&oacute;rias, para manter os alimentos separados e frescos por mais tempo.</p>
            <h4>5. Congele por&ccedil;&otilde;es extras</h4>
            <p>Nem sempre temos tempo para cozinhar diariamente, por isso, congele por&ccedil;&otilde;es extras. Marmitas congeladas s&atilde;o uma &oacute;tima op&ccedil;&atilde;o para os dias mais corridos.</p>

        `,
        conteudo2: `
            <h4>6. Tempere com modera&ccedil;&atilde;o</h4>
            <p>Use ervas frescas e especiarias para dar sabor aos pratos, reduzindo o uso de sal. Molhos &agrave; base de iogurte, mostarda ou lim&atilde;o s&atilde;o boas op&ccedil;&otilde;es para manter as refei&ccedil;&otilde;es saud&aacute;veis e saborosas.</p>
            <h4>7. Inclua snacks saud&aacute;veis</h4>
            <p>Al&eacute;m das refei&ccedil;&otilde;es principais, prepare snacks saud&aacute;veis como frutas cortadas, mix de nuts, iogurtes naturais e barrinhas de cereais caseiras para os lanches entre as refei&ccedil;&otilde;es.</p>
            <h4>8. Mantenha a variedade</h4>
            <p>N&atilde;o caia na monotonia. Tente variar as fontes de prote&iacute;na (frango, peixe, ovos, tofu), os tipos de gr&atilde;os (arroz integral, quinoa, cuscuz) e os vegetais, para garantir uma alimenta&ccedil;&atilde;o rica em diferentes nutrientes.</p>
            <h4>9. Hidrate-se</h4>
            <p>N&atilde;o se esque&ccedil;a de beber &aacute;gua. Uma boa hidrata&ccedil;&atilde;o &eacute; essencial para manter o metabolismo funcionando corretamente e para a absor&ccedil;&atilde;o dos nutrientes.</p>
            <h4>10. Organize-se</h4>
            <p>Uma boa organiza&ccedil;&atilde;o &eacute; chave. Separe um dia da semana para lavar, cortar e cozinhar os alimentos. Deixe tudo preparado para facilitar na hora de montar as marmitas.</p>
            <p>Seguindo essas dicas, voc&ecirc; ver&aacute; que preparar marmitas fitness saud&aacute;veis pode ser uma atividade simples e recompensadora.</p>
            <h3><strong>Temos o curso ideal para voc&ecirc;!</h3></strong>
            <p>&nbsp;</p>
        `,
        tags: [2, 3, 10, 1], 
        imgConteudo1: ImgMar1, 
        imgConteudo2: ImgMar2, 
        imgCard: ImgMar2, 
        imgAlt1: 'imagem apresentando o produto', 
        imgAlt2: 'imagem apresentando o produto', 
        autor: 'Teles', 
        avatarAutor: avatarPerfil1, 
        dataPost: '08/07/2024', 
        views: 200,
        linkCurso: 'https://www.aiqueproduto.com.br/produto/curso-de-marmitas-fitness-saudaveis-marmitaria-fit-versao-deluxe-online-2024'
    },

    {   id: 3, titulo: "Enriquecendo 2024: Dicas para um Segundo Semestre de Sucesso Financeiro",
        subtitulo: "Estamos no meio de 2024, e ainda há tempo para alcançar seus objetivos financeiros neste ano.",
        conteudo: `
            <p>Estamos no meio de 2024, e ainda h&aacute; tempo para alcan&ccedil;ar seus objetivos financeiros neste ano. Com planejamento e disciplina, voc&ecirc; pode terminar o ano com uma sa&uacute;de financeira muito melhor. Aqui est&atilde;o algumas dicas para ajud&aacute;-lo a enriquecer no segundo semestre de 2024:</p>
<h4>1. <strong>Reavalie suas Metas Financeiras</strong></h4>
<p>Se voc&ecirc; estabeleceu metas no in&iacute;cio do ano, agora &eacute; um bom momento para reavali&aacute;-las. Ajuste-as conforme necess&aacute;rio para garantir que ainda sejam realistas e alcan&ccedil;&aacute;veis.</p>
<h4>2. <strong>Revise seu Or&ccedil;amento</strong></h4>
<p>Examine seu or&ccedil;amento atual e veja onde voc&ecirc; pode fazer ajustes. Talvez algumas despesas possam ser reduzidas ou eliminadas para aumentar suas economias ou investimentos.</p>
<h4>3. <strong>Pague D&iacute;vidas com Juros Altos</strong></h4>
<p>Se voc&ecirc; ainda tem d&iacute;vidas com altas taxas de juros, como cart&otilde;es de cr&eacute;dito, priorize o pagamento dessas d&iacute;vidas. Isso pode economizar muito dinheiro em juros a longo prazo.</p>
<h4>4. <strong>Aumente sua Poupan&ccedil;a</strong></h4>
<p>Se voc&ecirc; ainda n&atilde;o tem um fundo de emerg&ecirc;ncia, comece a constru&iacute;-lo agora. Tente economizar pelo menos tr&ecirc;s a seis meses de despesas de subsist&ecirc;ncia para imprevistos.</p>
<h4>5. <strong>Diversifique seus Investimentos</strong></h4>

        `,
        conteudo2: `
<p>Avalie seu portf&oacute;lio de investimentos e considere diversificar mais. Distribua seus investimentos entre diferentes classes de ativos, como a&ccedil;&otilde;es, fundos imobili&aacute;rios e renda fixa.</p>
<h4>6. <strong>Eduque-se Financeiramente</strong></h4>
<p>Aproveite o segundo semestre para investir em sua educa&ccedil;&atilde;o financeira. Participe de cursos, leia livros e acompanhe especialistas em finan&ccedil;as pessoais.</p>
<h4>7. <strong>Aproveite Oportunidades de Benef&iacute;cios Fiscais</strong></h4>
<p>Verifique se h&aacute; benef&iacute;cios fiscais que voc&ecirc; pode aproveitar antes do final do ano. Contribui&ccedil;&otilde;es para planos de aposentadoria, por exemplo, podem oferecer dedu&ccedil;&otilde;es fiscais.</p>
<h4>8. <strong>Planeje suas Compras de Final de Ano</strong></h4>
<p>Com as festas de final de ano se aproximando, planeje suas compras com anteced&ecirc;ncia para evitar gastos excessivos. Fa&ccedil;a listas e estabele&ccedil;a um or&ccedil;amento para presentes e celebra&ccedil;&otilde;es.</p>
<h4>9. <strong>Revise seu Plano de Aposentadoria</strong></h4>
<p>Se voc&ecirc; ainda n&atilde;o come&ccedil;ou a planejar sua aposentadoria, n&atilde;o &eacute; tarde demais. Considere contribuir regularmente para um plano de previd&ecirc;ncia privada ou outro tipo de investimento de longo prazo.</p>
<h4>10. <strong>Adote H&aacute;bitos de Consumo Conscientes</strong></h4>
<p>Evite compras por impulso e adote um estilo de vida mais frugal. Avalie suas necessidades versus desejos e priorize o que realmente importa para sua sa&uacute;de financeira.</p>
<h3>Conclus&atilde;o</h3>
<p>Enriquecer no segundo semestre de 2024 &eacute; um objetivo alcan&ccedil;&aacute;vel com planejamento, disciplina e as estrat&eacute;gias certas. Comece hoje mesmo a aplicar essas dicas e veja como sua sa&uacute;de financeira pode melhorar at&eacute; o final do ano.</p>
<h3><strong>Temos o curso ideal para voc&ecirc;!</h3></strong> Invista em sua educa&ccedil;&atilde;o financeira e transforme suas finan&ccedil;as para um futuro mais pr&oacute;spero!</p>
        `,
        tags: [1, 3, 11, 9], 
        imgConteudo1: ImgFin1, 
        imgConteudo2: ImgFin2, 
        imgCard: ImgFin1, 
        imgAlt1: 'n sei para todos', 
        imgAlt2: 'n sei para todos', 
        autor: 'Teles', 
        avatarAutor: avatarPerfil1, 
        dataPost: '08/07/2024', 
        views: 150,
        linkCurso:'https://www.aiqueproduto.com.br/produto/formula-enriquecendo-2024'
    },
    {   id: 4, titulo: "Método Passo a Passo para Fazer e Vender Donuts Saborosos do Absoluto Zero",
        subtitulo: "Se você sempre quis aprender a fazer donuts deliciosos e ainda ganhar dinheiro com isso, está no lugar certo! Aqui está um guia passo a passo para ajudá-lo a começar essa doce jornada.",
        conteudo: `
        <h4>1. <strong>Prepara&ccedil;&atilde;o dos Ingredientes</strong></h4>
<ul>
<li><strong>Farinha de Trigo</strong>: 500g</li>
<li><strong>Fermento Biol&oacute;gico Seco</strong>: 10g</li>
<li><strong>Leite Morno</strong>: 250ml</li>
<li><strong>A&ccedil;&uacute;car</strong>: 75g</li>
<li><strong>Sal</strong>: 1 colher de ch&aacute;</li>
<li><strong>Ovos</strong>: 2</li>
<li><strong>Manteiga</strong>: 50g</li>
<li><strong>&Oacute;leo para Fritar</strong></li>
</ul>
<h4>2. <strong>Preparando a Massa</strong></h4>
<ul>
<li>Em uma tigela grande, misture a farinha, o a&ccedil;&uacute;car e o sal.</li>
<li>Adicione o fermento ao leite morno e deixe descansar por 5 minutos.</li>
<li>Bata os ovos e adicione &agrave; mistura de farinha.</li>
<li>Junte o leite com fermento e misture bem.</li>
<li>Adicione a manteiga derretida e amasse at&eacute; obter uma massa homog&ecirc;nea.</li>
<li>Deixe a massa descansar em um local morno por cerca de 1 hora, ou at&eacute; dobrar de tamanho.</li>
</ul>
<h4>3. <strong>Modelagem e Corte</strong></h4>
<ul>
<li>Ap&oacute;s o crescimento, abra a massa em uma superf&iacute;cie enfarinhada at&eacute; atingir uma espessura de 1 cm.</li>
<li>Corte os donuts com um cortador especial ou use dois cortadores redondos de tamanhos diferentes.</li>
<li>Coloque os donuts cortados em uma assadeira forrada e deixe crescer por mais 30 minutos.</li>
</ul>
<h4>4. <strong>Fritura</strong></h4>
<ul>
<li>Aque&ccedil;a o &oacute;leo a 180&deg;C.</li>
<li>Frite os donuts at&eacute; dourarem dos dois lados.</li>
<li>Retire e escorra em papel-toalha.</li>
</ul>

        `,
        conteudo2: `
<h4>5. <strong>Coberturas e Recheios</strong></h4>
<ul>
<li><strong>A&ccedil;&uacute;car e Canela</strong>: Passe os donuts ainda quentes em uma mistura de a&ccedil;&uacute;car e canela.</li>
<li><strong>Cobertura de Chocolate</strong>: Derreta chocolate de sua prefer&ecirc;ncia e mergulhe os donuts.</li>
<li><strong>Recheios</strong>: Use um saco de confeitar para rechear os donuts com creme de confeiteiro, geleia ou doce de leite.</li>
</ul>
<h4>6. <strong>Embalagem e Apresenta&ccedil;&atilde;o</strong></h4>
<ul>
<li>Utilize caixas pr&oacute;prias para donuts, garantindo que cheguem frescos ao cliente.</li>
<li>Capriche na apresenta&ccedil;&atilde;o, adicionando etiquetas personalizadas com o nome da sua marca.</li>
</ul>
<h4>7. <strong>Estrat&eacute;gias de Venda</strong></h4>
<ul>
<li><strong>Redes Sociais</strong>: Utilize Instagram e Facebook para mostrar seus produtos.</li>
<li><strong>Pontos de Venda</strong>: Considere vender em feiras, cafeterias e mercados locais.</li>
<li><strong>Pedidos Online</strong>: Crie um sistema de pedidos online para facilitar a compra.</li>
</ul>
<h4>8. <strong>Divulga&ccedil;&atilde;o e Marketing</strong></h4>
<ul>
<li>Crie promo&ccedil;&otilde;es e ofertas especiais para atrair novos clientes.</li>
<li>Fa&ccedil;a parcerias com influenciadores locais para divulgar seus donuts.</li>
</ul>
<p>Seguindo esses passos, voc&ecirc; estar&aacute; no caminho certo para n&atilde;o s&oacute; fazer donuts deliciosos, mas tamb&eacute;m criar um neg&oacute;cio de sucesso.</p>
<h3><strong>Temos o curso ideal para voc&ecirc;!</h3></strong>
        `,
        tags: [2, 3, 10, 1], 
        imgConteudo1: 'https://aiqueproduto.com.br/imagens/donuts/dona2.jpg', 
        imgConteudo2: 'https://aiqueproduto.com.br/imagens/donuts/dona3.jpg', 
        imgCard: 'https://aiqueproduto.com.br/imagens/donuts/dona3.jpg', 
        imgAlt1: 'n sei para todos', 
        imgAlt2: 'n sei para todos', 
        autor: 'Teles', 
        avatarAutor: avatarPerfil1, 
        dataPost: '28/07/2024', 
        views: 200,
        linkCurso:'https://aiqueproduto.com.br/produto/curso-de-donuts-do-zero-ceu-doce'
        
    },
    {   id: 5, 
        titulo: "Descubra a Radiestesia: Ciência das Radiações e Vibrações",
        subtitulo: "A Radiestesia é uma ciência fascinante que permite detectar, comparar e classificar as radiações e vibrações que compõem o universo.",
        conteudo: `
        <p>Qualquer pessoa pode desenvolver essa habilidade, pois &eacute; uma capacidade inerente a todos n&oacute;s. Para ajud&aacute;-lo a dominar essa t&eacute;cnica, oferecemos um curso completo em cinco m&oacute;dulos, com mais de 80 videoaulas te&oacute;ricas e pr&aacute;ticas, todas com certifica&ccedil;&atilde;o em Radiestesia Sensitiva.</p>
<h4>Estrutura do Curso</h4>
<h5><strong>M&oacute;dulo 1: Introdu&ccedil;&atilde;o &agrave; Radiestesia</strong></h5>
<ul>
<li><strong>No&ccedil;&otilde;es Te&oacute;ricas</strong>: Compreenda os fundamentos da radiestesia e radi&ocirc;nica.</li>
<li><strong>Primeiros Passos</strong>: Aprenda a usar os principais instrumentos radiest&eacute;sicos, como o p&ecirc;ndulo.</li>
</ul>
<h5><strong>M&oacute;dulo 2: T&eacute;cnicas de Detec&ccedil;&atilde;o</strong></h5>
<ul>
<li><strong>Detec&ccedil;&atilde;o de Energias</strong>: Desenvolva habilidades para identificar diferentes tipos de radia&ccedil;&otilde;es.</li>
<li><strong>Pr&aacute;tica com Instrumentos</strong>: Aprofunde-se no uso de diversos equipamentos radiest&eacute;sicos.</li>
</ul>
<h5><strong>M&oacute;dulo 3: Aplica&ccedil;&otilde;es Pr&aacute;ticas</strong></h5>
<ul>
<li><strong>Tratamento Pessoal</strong>: Use a radiestesia para melhorar sua sa&uacute;de e bem-estar.</li>
<li><strong>Aplica&ccedil;&otilde;es Empresariais</strong>: Aprenda a aplicar t&eacute;cnicas de radiestesia em ambientes corporativos.</li>
</ul>
<h5><strong>M&oacute;dulo 4: Radiestesia para Im&oacute;veis</strong></h5>
<ul>
<li><strong>Energia dos Ambientes</strong>: Avalie e ajuste as energias em resid&ecirc;ncias e espa&ccedil;os comerciais.</li>
<li><strong>Harmoniza&ccedil;&atilde;o de Ambientes</strong>: T&eacute;cnicas para equilibrar e harmonizar as energias de diferentes locais.</li>
</ul>

        `,
        conteudo2: `
<h5><strong>M&oacute;dulo 5: Aperfei&ccedil;oamento e Certifica&ccedil;&atilde;o</strong></h5>
<ul>
<li><strong>Casos Pr&aacute;ticos</strong>: An&aacute;lise e solu&ccedil;&atilde;o de casos reais.</li>
<li><strong>Certifica&ccedil;&atilde;o</strong>: Receba seu certificado de Radiestesia Sensitiva.</li>
</ul>
<h4>Acompanhamento e Suporte</h4>
<p>Al&eacute;m das videoaulas gravadas, voc&ecirc; ter&aacute; meu acompanhamento pessoal em cada aula atrav&eacute;s de um chat de perguntas e respostas. Tamb&eacute;m oferecemos aulas online peri&oacute;dicas via Zoom, onde voc&ecirc; pode interagir com a turma e tirar d&uacute;vidas em tempo real.</p>
<h4>Objetivos do Curso</h4>
<p>Nosso curso visa desenvolver e capacitar qualquer pessoa, seja profissional ou n&atilde;o, no uso pr&aacute;tico da t&eacute;cnica de radiestesia para tratamentos pessoais, empresariais e de im&oacute;veis.</p>
<h4>A Quem se Destina</h4>
<p>Este curso &eacute; destinado a todos. N&atilde;o h&aacute; pr&eacute;-requisitos para come&ccedil;ar a aprender radiestesia.</p>
<h4>O que Vamos Aprender</h4>
<ul>
<li>No&ccedil;&otilde;es te&oacute;ricas de Radiestesia e Radi&ocirc;nica.</li>
<li>Utiliza&ccedil;&atilde;o dos principais instrumentos radiest&eacute;sicos.</li>
<li>T&eacute;cnica completa de tratamento.</li>
</ul>
<p>O curso &eacute; totalmente pr&aacute;tico. Desde o primeiro m&oacute;dulo, voc&ecirc; j&aacute; adquirir&aacute; aptid&atilde;o no uso do p&ecirc;ndulo e outras ferramentas para atendimento.</p>
<p><strong>Nota</strong>: Este curso n&atilde;o substitui o parecer m&eacute;dico profissional. Sempre consulte um m&eacute;dico para tratar de assuntos relativos &agrave; sa&uacute;de.</p>
<p><span style="font-size: 18pt;"><strong>Temos o curso ideal para voc&ecirc;!</strong></span></p>
        `,
        tags: [5, 9, 3], 
        imgConteudo1: 'https://www.aiqueproduto.com.br/imagens/radiestesia/2.webp', 
        imgConteudo2: 'https://www.aiqueproduto.com.br/imagens/radiestesia/4.webp', 
        imgCard: 'https://www.aiqueproduto.com.br/imagens/radiestesia/4.webp', 
        imgAlt1: 'imagem de radiestesia', 
        imgAlt2: 'imagem de radiestesia 2', 
        autor: 'Teles', 
        avatarAutor: avatarPerfil1, 
        dataPost: '28/07/2024', 
        views: 150,
        linkCurso:'https://www.aiqueproduto.com.br/produto/formacao-em-radiestesia-sensitiva-2024'
        
    },
    {   id: 6, 
        titulo: "Adestramento Canino em Casa: Dicas e Passos Essenciais",
        subtitulo: "Adestrar seu cão em casa pode ser uma experiência recompensadora, fortalecendo o vínculo entre vocês e garantindo um comportamento adequado em diversas situações",
        conteudo: `
            <p>Aqui est&atilde;o algumas dicas e um m&eacute;todo passo a passo para ajudar voc&ecirc; a come&ccedil;ar.</p>
<h4>1. <strong>Prepara&ccedil;&atilde;o e Planejamento</strong></h4>
<ul>
<li><strong>Defina Objetivos Claros</strong>: Determine quais comportamentos voc&ecirc; deseja ensinar, como sentar, ficar, vir quando chamado, e comportamentos espec&iacute;ficos que precisam ser corrigidos.</li>
<li><strong>Ambiente Adequado</strong>: Escolha um local tranquilo e livre de distra&ccedil;&otilde;es para as sess&otilde;es de treino.</li>
</ul>
<h4>2. <strong>Ferramentas Essenciais</strong></h4>
<ul>
<li><strong>Coleira e Guia</strong>: Para treinar comandos b&aacute;sicos e passeios.</li>
<li><strong>Petiscos</strong>: Recompensas para incentivar o bom comportamento.</li>
<li><strong>Brinquedos</strong>: Para refor&ccedil;o positivo e momentos de lazer.</li>
<li><strong>Clicker</strong>: Ferramenta de treino para marcar comportamentos desejados.</li>
</ul>
<h4>3. <strong>M&eacute;todos de Treinamento</strong></h4>
<ul>
<li><strong>Refor&ccedil;o Positivo</strong>: Recompense seu c&atilde;o com petiscos, carinho ou brinquedos sempre que ele obedecer um comando corretamente.</li>
<li><strong>Consist&ecirc;ncia</strong>: Seja consistente nos comandos e nas recompensas. Todos os membros da fam&iacute;lia devem seguir as mesmas regras e comandos.</li>
<li><strong>Sess&otilde;es Curtas e Frequentes</strong>: Realize sess&otilde;es de treino de 5 a 10 minutos v&aacute;rias vezes ao dia. C&atilde;es aprendem melhor em per&iacute;odos curtos e repetitivos.</li>
</ul>
<h4>4. <strong>Comandos B&aacute;sicos</strong></h4>
<ul>
<li><strong>Sentar</strong>: Segure um petisco perto do nariz do c&atilde;o e mova-o para cima, fazendo com que ele sente naturalmente. Assim que ele sentar, diga &ldquo;senta&rdquo; e d&ecirc; a recompensa.</li>
<li><strong>Ficar</strong>: Pe&ccedil;a para o c&atilde;o sentar, depois mostre a palma da m&atilde;o e diga &ldquo;fica&rdquo;. D&ecirc; alguns passos para tr&aacute;s e, se ele ficar, recompense-o.</li>
<li><strong>Vir Quando Chamado</strong>: Use um tom de voz alegre, chame o nome do seu c&atilde;o seguido de &ldquo;vem&rdquo;. Quando ele vier, recompense-o generosamente.</li>
<li><strong>Deitar</strong>: Com o c&atilde;o sentado, segure um petisco e mova-o lentamente para o ch&atilde;o. Assim que ele se deitar, diga &ldquo;deita&rdquo; e ofere&ccedil;a a recompensa.</li>
</ul>

        `,
        conteudo2: `
            <h4>5. <strong>Corre&ccedil;&atilde;o de Comportamentos Indesejados</strong></h4>
<ul>
<li><strong>Ignorar Comportamentos Inadequados</strong>: Evite refor&ccedil;ar comportamentos indesejados, como pular nas pessoas. Ignore seu c&atilde;o at&eacute; que ele se acalme.</li>
<li><strong>Redirecionamento</strong>: Se o c&atilde;o estiver mordendo m&oacute;veis, ofere&ccedil;a um brinquedo apropriado para morder.</li>
<li><strong>Uso do Comando &ldquo;N&atilde;o&rdquo;</strong>: Utilize um tom firme e diga &ldquo;n&atilde;o&rdquo; quando seu c&atilde;o fizer algo inadequado, seguido de redirecionamento para um comportamento aceit&aacute;vel.</li>
</ul>
<h4>6. <strong>Socializa&ccedil;&atilde;o</strong></h4>
<ul>
<li><strong>Exposi&ccedil;&atilde;o a Diferentes Ambientes</strong>: Leve seu c&atilde;o para passeios em diferentes locais, como parques, ruas movimentadas e &aacute;reas calmas, para que ele se acostume com diversos est&iacute;mulos.</li>
<li><strong>Intera&ccedil;&atilde;o com Outros C&atilde;es</strong>: Permita que seu c&atilde;o interaja com outros c&atilde;es de maneira controlada e segura para desenvolver habilidades sociais.</li>
</ul>
<h4>7. <strong>Monitoramento e Ajustes</strong></h4>
<ul>
<li><strong>Observe o Progresso</strong>: Monitore o progresso do seu c&atilde;o e ajuste as t&eacute;cnicas conforme necess&aacute;rio. Alguns c&atilde;es podem precisar de mais tempo para aprender certos comandos.</li>
<li><strong>Paci&ecirc;ncia e Persist&ecirc;ncia</strong>: Seja paciente e persistente. O aprendizado pode levar tempo, mas a consist&ecirc;ncia trar&aacute; resultados.</li>
</ul>
<p>Adestrar seu c&atilde;o em casa pode ser uma jornada gratificante, tanto para voc&ecirc; quanto para seu amigo peludo. Com dedica&ccedil;&atilde;o e amor, voc&ecirc; conseguir&aacute; ensinar os comportamentos desejados e melhorar a conviv&ecirc;ncia di&aacute;ria.</p>
<p><strong><span style="font-size: 18pt;">Temos o curso ideal para voc&ecirc;!</span></strong></p>
        `,
        tags: [3, 5, 9, 16], 
        imgConteudo1: ImgAdestramento, 
        imgConteudo2: 'https://www.aiqueproduto.com.br/imagens/adestramento/ades2.jpg', 
        imgCard: 'https://www.aiqueproduto.com.br/imagens/adestramento/ades2.jpg', 
        imgAlt1: 'imagem de cachorro', 
        imgAlt2: 'imagem de cachorro 2', 
        autor: 'Teles', 
        avatarAutor: avatarPerfil1, 
        dataPost: '28/07/2024', 
        views: 100,
        linkCurso:'https://www.aiqueproduto.com.br/produto/adestramento-canino-em-casa-ebook'
    },
]

export const postsDados = postsData.map(post => ({
    ...post,
    slug: generateSlug(post.titulo)
}));

/*
export const posts = [
    {   id: 1, 
        titulo: "Transforme-se em um Barbeiro Profissional: Domine a Arte do Corte!",
        subtitulo: "Descubra Como Se Tornar um Barbeiro de Sucesso, Começando do Zero!",
        conteudo: `
        
        `,
        conteudo2: `

        `,
        tags: [2], 
        imgConteudo1: ImgText1, 
        imgConteudo2: ImgText1, 
        imgCard: ImgText1, 
        imgAlt1: 'imagem de', 
        imgAlt2: 'imagem de', 
        autor: 'Teles', 
        avatarAutor: avatarPerfil1, 
        dataPost: '08/07/2024', 
        views: 150,
        linkCurso:''
        
    },

];
*/


//https://fiddle.tiny.cloud/ <- para editar o texto e colocar em conteudo