import React, { useEffect, useState } from 'react';
import {Container} from "./styles";
import Slider from "react-slick";
import Card from '../Cards/Card';

const Carousel = ({data}) => {

    //setttings do Slick
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 5.8, //aqui
        slidesToScroll: 1,
        initialSlide: 0,
        className: "carousel",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 930,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 670,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 340,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    return(
        <Container>
            <div className="cont-carousel">
                <Slider {...settings}>
                    {Array.isArray(data) && data.map((post) => (
                        <div  key={post.id} className='area-card'>
                            <Card titulo={post.titulo} ImgText={post.imgCard} imgAlt1={post.imgAlt1} link={`/post/${post.slug}`}/>
                        </div>
                    ))}
                </Slider>
            </div>
        </Container>
        
    );
}
export default Carousel; 
