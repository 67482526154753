import styled from "styled-components";
import {Instagram} from '@styled-icons/boxicons-logos/Instagram';
import {Tiktok} from "@styled-icons/boxicons-logos/Tiktok";
import {Youtube} from "@styled-icons/boxicons-logos/Youtube";

export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    justify-content: space-between;
`;


export const InstagramIcon = styled(Instagram)`
    width: 100%;
    max-width: 40px;
    color: ${({ color }) => color || 'white'};
`;

export const TiktokIcon = styled(Tiktok)`
    width: 100%;
    max-width: 40px;
    color: ${({ color }) => color || 'white'};
`;

export const YoutubeIcon = styled(Youtube)`
    width: 100%;
    max-width: 40px;
    color: ${({ color }) => color || 'white'};
`;