import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    height: 100px;
    background-color: white;
    margin-top: 5px;
    box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.3);


    .card{
        display: flex;
        width: 100%;
    }


    .image-card{
        display: flex;
        width: 150px;

        img{
            width: 100%;
            height: auto;
        }
    }

    .cont-details{
        display: block;
        margin-left: 2px;
        width: 100%;
        max-width: 250px;

        .titulo-card{
            margin-top: 3px;
            word-wrap: break-word; /* Quebrar palavras longas */
            word-break: break-all; /* Quebrar palavras longas */
            white-space: normal; /* Permitir quebras de linha normais */
    
            a{
                text-decoration: none;
            }
            
            a:visited {
                color: inherit; /* Mantém a cor do link visitado igual ao texto não visitado */
            }
        }

        .titulo-card:hover {
            cursor: pointer;
            position: relative;
            text-decoration: underline;
        }
        
    }

    @media (max-width: 1200px) {

    }
`;