import React from "react";
import { Container } from "./styles";



const CardMain = ({titulo, ImgText, avatarPerfil, nomeAutor, dataPost, imgAlt1, imgAlt2, link}) => {

    return(
        <Container>
            <div className="text-main-details">
                <div className="text-main">
                    <span className="text-card">
                        <a href={link}>{titulo}</a>
                    </span>
                </div>

                <div className="text-details-image">
                    <div className="image-perfil">
                        <img src={avatarPerfil} alt={imgAlt1}/>
                    </div>
                    <div className="cont-text-details">
                        <div className="text-details">
                            <div className="name-autor">por {nomeAutor}</div>
                            <div className="date-post">{dataPost}</div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="image-text">
                <img src={ImgText} alt={imgAlt2}/>
            </div>
         
        </Container>
    );
}
export default CardMain;