import React from "react";
import { Container } from "./styles";


const Card = ({titulo, ImgText, imgAlt1, link}) => {

    return(
        <Container>
            <div className='card'>
                <div className="image-card">
                    <img src={ImgText} alt={imgAlt1}/>
                </div>
                <div className="cont-details">
                    <div className="titulo-card">
                        {link ? (
                            <a href={link}><h5>{titulo}</h5></a>
                        ) : (
                            <h5>{titulo}</h5>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
}
export default Card;


               