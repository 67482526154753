export const tags = [
    { id: 1, nome: 'Finanças e Negócios', nomeUrl: 'financas-e-negocios'},
    { id: 2, nome: 'Curso Profissionalizante', nomeUrl: 'curso-profissionalizante'},
    { id: 3, nome: 'Educação', nomeUrl: 'educacao'},
    { id: 4, nome: 'Tecnologia', nomeUrl: 'tecnologia'},
    { id: 5, nome: 'Saúde e Bem-estar', nomeUrl: 'saude-e-bem-estar'},
    { id: 6, nome: 'Arte e Design', nomeUrl: 'arte-e-design'},
    { id: 7, nome: 'Linguagens', nomeUrl: 'linguagens'},
    { id: 8, nome: 'Marketing e Comunicação', nomeUrl: 'marketing-e-comunicacao'},
    { id: 9, nome: 'Desenvolvimento Pessoal', nomeUrl: 'desenvolvimento-pessoal'},
    { id: 10, nome: 'Culinária e Gastronomia', nomeUrl: 'culinaria-e-gastronomia'},
    { id: 11, nome: 'Ciências e Matemática', nomeUrl: 'ciencias-e-matematica'},
    { id: 12, nome: 'Engenharia e Arquitetura', nomeUrl: 'engenharia-e-arquitetura'},
    { id: 13, nome: 'Humanas e Sociais', nomeUrl: 'humanas-e-sociais'},
    { id: 14, nome: 'Moda e Estilo', nomeUrl: 'moda-e-estilo'},
    { id: 15, nome: 'Turismo e Hospitalidade', nomeUrl: 'turismo-e-hospitalidade'},
    { id: 16, nome: 'Esporte e Lazer', nomeUrl: 'esporte-e-lazer'},
    { id: 17, nome: 'Música e Entretenimento', nomeUrl: 'musica-e-entretenimento'},
    { id: 18, nome: 'Ciências Ambientais', nomeUrl: 'ciencias-ambientais'},
    { id: 19, nome: 'Direito e Legislação', nomeUrl: 'direito-e-legislacao'},
    { id: 20, nome: 'Idiomas', nomeUrl: 'idiomas'},
];
