import styled from "styled-components";


export const Container = styled.div`
    display: block;
    width: 100%;
    height: 300px;
    

    .cont-footer-one{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 220px;
        background-color: #101E28;
    }

    .cont-footer-two{
        display: flex;
        width: 100%;
        height: 80px;
        background-color: #0A1117;
        justify-content: center;
        align-items: center;


        .bar-copy{
            display: flex;
            height: auto;

            span{
                font-size: 16px;
                color: white;
                font-weight: 700;
            }
            
        }
    }

`;


