import React from "react";
import { Container } from "./styles";

const Button = ({ onClick, children }) => {
    return (
        <Container onClick={onClick}>
            <span>{children}</span>
        </Container>
    );
}

export default Button;
