import styled from 'styled-components';

export const Container = styled.div`
    display: block;
    width: 100%;
    max-width: 1300px;
    height: 100%;

    .anuncio-home-1{
        display: none;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        border: 1px solid black;
        width: 100%;
        height: 90px;
    }

    .cont-mais-vistas{
        display: none;
        width: 100%;


        .title-bar{
            display: flex;
            justify-content: center;

            span{
                width: 100%;
                max-width: 900px;
            }
        }

        .area-cards{
            display: flex;
            width: 100%;
            justify-content: center;
    
        }

        .corousel-cards{
            width: 100%;
            max-width: 860px;
    
        }
    }

    .area-button{
        display: flex;
        width: 100%;
        max-width: 880px;
        height: 70px;
        justify-content: center;
        align-items: center;
    }

    .cont-main-bar-lateral{
        display: flex;
        width: 100%;

    }

    .area-posts{
        width: 100%;
        max-width: 900px;
        min-height: 800px;
    }

    .area-lateral{
        width: 100%;
        max-width: 400px;
        margin-left: 0.5%;
    }

    .title-bar{
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;

        span{
            font-weight: bold;
            color: #f53899;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        }
    }


    /* Telas pequenas (smartphones) */
    @media (max-width: 1200px) {

        .cont-mais-vistas{
            display: block;
        }

        .area-lateral{
            display: none;
        }

        .area-posts{
            max-width: 900px;
        }

        .area-button{
            max-width: 100%;
        }

        .cont-main-bar-lateral{
            justify-content: center;
        }
    }
`;

