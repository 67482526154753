import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto; //Centraliza quando é block

  .titulo-subtitulo {
    margin-bottom: 20px;

    h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
      color: #555;
    }
  }

  .imagem-capa-inicio {
    margin-bottom: 20px;

    img{
        width: 100%;
        max-width: 800px;
        height: auto;
    }
  }

  .area-conteudo-post {
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    .conteudo-inicial,
    .conteudo-restante {
      text-align: justify;

      p{
        margin-bottom: 10px;
      }

      ul{
        display: flex;
        flex-direction: column;
        padding: 1% 0px 0px 3%;
        margin: 5px 0px 5px 0px;
      }
    }

    .imagem-conteudo {
        display: flex;
        align-self: center;
        justify-content: center;
        width: 100%;
        max-width: 800px;

        img{
            width: 100%;
            max-width: 500px;
            height: auto;
        }
    }
  }

  .anuncio-home-1{
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border: 1px solid black;
    width: 100%;
    height: 90px;
  }

  .area-info-autor {
    margin-top: 40px;
    padding: 20px 0px 20px 0px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    .autor {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-size: 1rem;
        color: #555;
      }

      img {
        border-radius: 50%;
      }
    }
  }

  .area-button-buy{
    display: flex;
    justify-content: center;
    width: 100%;

    a{
      max-width: 120px;
      width: 100%;
      text-decoration: none;

      span{
        font-size: 1.2em;
      }
    }
  }

  .area-tags {
    margin: 20px 0;
  }

  .tags {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .tag {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 5px 0;
    box-sizing: border-box;
    white-space: nowrap;
  }

  .area-text-leia{
    margin-top: 20px;
    padding-top: 20px;

    span{
      font-weight: bold;
      color: #f53899;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    }

  }

  .area-button{
    display: flex;
    width: 100%;
    max-width: 880px;
    height: 70px;
    justify-content: center;
    align-items: center;
  }

  /* Telas pequenas (smartphones) */
  @media (max-width: 1200px) {
    padding: 20px 1% 20px 1%;

    .area-button{
      max-width: 100%;
    }
  }
`;
