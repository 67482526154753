import styled from "styled-components";


export const Container = styled.button`
    display: flex;
    width: 100%;
    max-width: 150px;
    border-radius: 7px;
    height: 40px;
    background-color: var(--rosaTn);
    justify-content: center;
    align-items: center;
    box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    color: white;
    cursor: pointer; /* Adiciona um cursor de ponteiro para feedback visual */
    border: none; /* Remove bordas padrão do botão */
`;