import React from "react";
import { Container, ContEmblema, ContRSociais } from "./styles";
import IconSociais from "../IconSociais";

const NavBar = () => {
    return(
        <Container>
            <a href="/">
                <ContEmblema>
                    <div className="emblema">
                        Blog - AIQueproduto
                    </div>
                </ContEmblema>
            </a>
            <ContRSociais>
                <IconSociais color="#101E28"/>
            </ContRSociais>
            
        </Container>
    );
}
export default NavBar;