import React from "react";
import { Container} from "./styles";
import IconSociais from "../IconSociais";

const Footer = () => {
    return(
        <Container>
            <div className="cont-footer-one">
                <IconSociais color="white"/>
            </div>
            <div className="cont-footer-two">
                <div className="bar-copy">
                    <span>Copyright &copy; {new Date().getFullYear()} by SamAITech. Todos os direitos reservados.</span>
                </div>
            </div>
        </Container>
    );
}
export default Footer;