import styled from "styled-components";


export const Container = styled.main`
    display: flex;
    width: 100%;
    min-height: 1000px;
    justify-content: center;
    margin-top: 90px;

    @media (max-width: 1000px) {
        padding: 1%;
    }
    
`;