import React, { useEffect, useState} from 'react';
import { Container } from './styles';
import Card from '../../components/Cards/Card';
import CardMain from '../../components/Cards/CardMain';
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';
import Loading from '../../components/Loading/Loading';
import Layout from '../../components/Layout';
import { postsDados } from '../../dados/posts';




const Home = () => {

    const [posts, setPosts] = useState([]);
    const [visiblePosts, setVisiblePosts] = useState(5); // Estado para controlar o número de posts visíveis
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
        try {
            setPosts(postsDados)
            setLoading(false);
        
        } catch (err) {
        setError(err);
        setLoading(false);
        }
    }, []);

  


    const handleLoadMore = () => {
        setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 5); // Incrementa o número de posts visíveis
    };

    if (loading) {
        return (
        <Container>
            <Loading />
        </Container>
        );
    }

    if (error) {
        return <div>Erro: {error}</div>;
    }

    // Ordena os posts por número de visualizações em ordem decrescente
    const sortedPosts = [...posts].sort((a, b) => b.views - a.views);
    // Ordena os posts publicados mais recentes
    const recentesPosts = [...posts].sort((a, b) => b.id - a.id);
  

    return (
        <Layout>
            <Container>
                <div className='cont-mais-vistas'>
                    <div className='title-bar'>
                    <span>MAIS VISTAS</span>
                    </div>
                    <div className='area-cards'>
                    <div className='corousel-cards'>
                        <Carousel data={sortedPosts} />
                    </div>
                    </div>
                </div>
                <div className='cont-main-bar-lateral'>
                    <div className='area-posts'>
                    <div className="anuncio-home-1">
                        Anuncie Aqui
                    </div>
                    {Array.isArray(recentesPosts) && recentesPosts.slice(0, visiblePosts).map((post) => (
                        <div key={post.id}>
                        <CardMain
                            link={`/post/${post.slug}`}
                            titulo={post.titulo}
                            ImgText={post.imgCard}
                            avatarPerfil={post.avatarAutor}
                            imgAlt1={post.imgAlt1}
                            imgAlt2={post.imgAlt2}
                            nomeAutor={post.autor}
                            dataPost={post.dataPost}
                        />
                        </div>
                    ))}
                    
                    </div>
                    <div className='area-lateral'>
                        <div className='title-bar'>
                            <span>MAIS VISTAS</span>
                        </div>
                        {Array.isArray(sortedPosts) && sortedPosts.slice(0, 5).map((post) => (
                            <div key={post.id} className='area-card'>
                                <Card
                                    titulo={post.titulo}
                                    ImgText={post.imgCard}
                                    imgAlt1={post.imgAlt1}
                                    link={`/post/${post.slug}`}
                                />
                            
                            </div>
                        ))}

                        <div className="anuncio-home-1">
                            Anuncie Aqui
                        </div>
                    </div>
                </div>
                <div className='area-button'>
                    {visiblePosts < posts.length && (
                    <Button onClick={handleLoadMore}>VER MAIS</Button>
                    )}
                </div>
            </Container>
        </Layout>
    );
};

export default Home;

