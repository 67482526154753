import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 880px;
    height: 220px;
    background-color: white;
    margin-top: 20px;
    box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    justify-content: space-between;
    

    .text-main-details{
        order: 1;
        display: block;
    }

    .text-main{
        display: block;
        width: 100%;
        max-width: 660px;
        min-height: 90px;
        max-height: 200px;
        margin: 0px 0px 10px 1%;
        margin-top: 5px;

        .text-card{
            display: block;
            width: 100%;
            font-size: 23px;
            font-weight: bold;
            word-wrap: break-word; /* Quebrar palavras longas */
            word-break: break-all; /* Quebrar palavras longas */
            white-space: normal; /* Permitir quebras de linha normais */
    
            a{
                text-decoration: none;
            }
            a:visited {
                color: inherit; /* Mantém a cor do link visitado igual ao texto não visitado */
            }
        }

        .text-card:hover {
            cursor: pointer;
            position: relative;
            text-decoration: underline;
        }
        
    }

    .image-text{
        order: 2;
        display: flex;
        width: 100%;
        max-width: 200px;
        height: 100%;
        border-radius: 5px;

        img{
            width: 100%;
            max-width: 200px;
            height: 100%;
            border-radius: 15px 5px 5px 15px;
        }
    }

    .text-details-image{
        display: flex;
        margin-left: 1%;
        bottom: 0;
    }

    .image-perfil{
        display: flex;
        width: 100px;
        height: 100px;
        
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        img{
            width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }

    .cont-text-details{
        display: flex;
        align-items: center;
        margin-left: 1%;
    }

    .text-details{
        display: block;
    }


    /* Telas pequenas (smartphones) */
    @media (max-width: 950px) {
        display: flex;
        flex-direction: column;
        min-height: 220px;
        height: auto;

        .text-main-details{
            order: 2;
        }

        .text-main{
            max-height: 400px;
        }

        .image-text{
            order: 1;
            width: 100%;
            max-width: 100%;
            height: 100%;
            border-radius: 0px;
            justify-content: center;

            img{
                width: 100%;
                max-width: 500px;
                height: 300px;
                border-radius: 10px;
            }
        }
    }

`;