// src/components/Layout.js
import React from 'react';
import Footer from './Footer';
import Main from './Main';
import NavBar from './NavBar';

const Layout = ({ children }) => {
  return (
    <div>
      <NavBar/>
      <Main>{children}</Main>
      <Footer />
    </div>
  );
};

export default Layout;
