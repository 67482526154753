import React from "react";
import { Container, InstagramIcon, TiktokIcon, YoutubeIcon } from "./styles";

const IconSociais = ({color}) => {
    return(
        <Container>
            <InstagramIcon color={color}/>
            <TiktokIcon color={color}/>
            <YoutubeIcon color={color}/>
        </Container>
    );
}
export default IconSociais;