import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import DOMPurify from 'dompurify';
import { postsDados } from '../../dados/posts';
import { tags } from '../../dados/tags'; 
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import CardMain from '../../components/Cards/CardMain';

const PostDetail = () => {
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visiblePosts, setVisiblePosts] = useState(3); // Estado para controlar o número de posts visíveis

  useEffect(() => {
    // Simulando a busca do produto pelo slug
    const foundPost = postsDados.find(p => p.slug === slug);

    if (foundPost) {
      setPosts(postsDados)
      setPost(foundPost);
    } else {
      setError('Produto não encontrado');
    }
    setLoading(false);
  }, [slug]);
    

  if (loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  const handleLoadMore = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 2); // Incrementa o número de posts visíveis
  };

  const getTagNames = (postTags) => {
    return postTags.map(tagId => {
        const tag = tags.find(t => t.id === tagId);
        return tag ? tag.nome : null;
    }).filter(tagName => tagName !== null);
  }

  const tagNames = getTagNames(post.tags);


  return (
    <Layout>
      <Container>
        <div className='titulo-subtitulo'>
          <h1>{post.titulo}</h1>
          <p>{post.subtitulo}</p>
        </div>
        <div className='imagem-capa-inicio'>
          <img src={post.imgConteudo1} alt={post.imgAlt1}/>
        </div>
        <div className='area-conteudo-post'>
          <div className='conteudo-inicial'>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.conteudo) }} />
          </div>
          <div className="anuncio-home-1">
            Anuncie Aqui
          </div>
          <div className='imagem-conteudo'>
            <img src={post.imgConteudo2} alt={post.imgAlt2}/>
          </div>
          <div className='conteudo-restante'>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.conteudo2) }} />
            <div className='area-button-buy'>
              <a href={post.linkCurso} target="_blank" rel="noopener noreferrer">
                <Button>
                  Aprenda Mais
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div className='area-info-autor'>
          <div className='autor'>
            <img src={post.avatarAutor} alt={`Avatar de ${post.autor}`} width={50} height={50} />
            <div>
              <p>Por {post.autor}</p>
              <p>{post.dataPost}</p>
            </div>
          </div>
        </div>
        <div className='area-tags'>
          <h3>Tags:</h3>
          <div className='tags'>
            {tagNames.map((tagName, index) => (
              <span key={index} className='tag'>{tagName}</span>
            ))}
          </div>
        </div>
        <div className='area-text-leia'>
          <span>LEIA TAMBÉM</span>
        </div>
        <div className='area-detalhes-cards-mais'>
          {Array.isArray(posts) && posts.slice(0, visiblePosts).map((post) => (
                        <div key={post.id}>
                        <CardMain
                            link={`/post/${post.slug}`}
                            titulo={post.titulo}
                            ImgText={post.imgCard}
                            avatarPerfil={post.avatarAutor}
                            imgAlt1={post.imgAlt1}
                            imgAlt2={post.imgAlt2}
                            nomeAutor={post.autor}
                            dataPost={post.dataPost}
                        />
                        </div>
            ))}
        </div>
        <div className='area-button'>
            {visiblePosts < posts.length && (
            <Button onClick={handleLoadMore}>VER MAIS</Button>
            )}
        </div>
      </Container>
    </Layout>
  );
};

export default PostDetail;