import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 90px;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 10000; /* Certifique-se de que a barra esteja acima de outros conteúdos */

    a{
        text-decoration: none;
    }

    @media (max-width: 380px) {
        justify-content: center;
    }
`;

export const ContEmblema = styled.div`
    display: flex;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: var(--rosaTn);
    margin-left: 2%;
    border-radius: 10px;
    
    .emblema{
        font-size: larger;
        font-weight: bold;
        color: white;
    }

    @media (max-width: 380px) {
        max-width: 200px;
        width: 100%;

        .emblema{
            font-size: medium;
            font-weight: 600;
            color: white;
        }
    }

`;

export const ContRSociais = styled.div`
    display: flex;
    width: 100%;
    max-width: 150px;
    margin-right: 2%;

    @media (max-width: 330px) {
        display: none;
    }

`;