import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 900px;
    height: auto;

    .cont-carousel{
        display: flex;
        width: 100%;
        height: 100%;
    }

    .carousel{
        width: 100%;
        height: 100%;

        .slick-prev:before {
            color: #f53899; 
        }

        .slick-next:before {
            color: #f53899; 
        }
    }

    .area-card{
        width: 100%;
        max-width: 400px;
        height: 100px;
    }


    
`;