// styles/globalstyles.js
import { createGlobalStyle } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #__next {
    height: 100%;
    background-color: #ECEBEB;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3 {
    font-weight: bold;   
  }

  :root {
    --mainColor: #33328F;
    --secondaryColor: #1785C2; 
    --background: #F5F5F5;
    --grey: #cccccc;
    --rosaTn: #f53899;
    --azul: #6462d3;
    --azul-rgb: 100, 98, 211;
  }
`;
export default GlobalStyle;